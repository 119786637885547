<template>
  <b-card>
    <!-- begin: header Content     -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("DEVICE.FACIAL.PAGE.HEADER") }}
        </h3>
        <span class="text-muted mt-3 font-weight-bold">
          {{ $t("DEVICE.FACIAL.PAGE.SUBTITLE") }}</span
        >
      </div>
      <div class="card-toolbar">
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>
        <b-button
          variant="primary"
          class="font-weight-bolder"
          v-b-toggle.facial-device-list-sidebar
          v-on:click="openSideBar"
        >
          {{ $t("BUTTON.ADD") }}</b-button
        >
        <b-sidebar
          ref="facial-device-list-sidebar-ref"
          id="facial-device-list-sidebar"
          backdrop
          right
          lazy
          :title="$t('DEVICEFORM.HEADER')"
          sidebar-class="offcanvas p-7"
          no-header
          bg-variant="white"
        >
          <template #default="{ hide }">
            <div
              class="
                offcanvas-header
                d-flex
                align-items-center
                justify-content-between
                pb-5
              "
            >
              <h3 class="font-weight-bolder m-0">
                {{ $t("DEVICE.FACIAL.PAGE.HEADERFORM") }}
              </h3>
              <button
                class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
                v-on:click="hide"
              >
                <i class="ki ki-close icon-lg"></i>
              </button>
            </div>
            <div class="align-items-center">
              <FacialDeviceForm></FacialDeviceForm>
            </div>
          </template>
        </b-sidebar>
      </div>
    </div>
    <!-- end: header Content     -->
    <div>
      <b-alert
        variant="info"
        :show="dismissCountDown"
        fade
        dismissible
        @dismissed="dismissCountDown = 0"
      >
        {{ successMessage }}
      </b-alert>
    </div>

    <FacialDeviceTable :reload="reload"></FacialDeviceTable>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FacialDeviceTable from "@/modules/device/components/facial/FacialDeviceTable";
import FacialDeviceForm from "@/modules/device/components/facial/FacialDeviceForm";
import { mapGetters } from "vuex";

export default {
  name: "FacialDevicePage",
  components: { FacialDeviceForm, FacialDeviceTable },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.DEVICE") },
      { title: this.$t("SIDEBAR.FACIAL"), route: "/device/facial" },
    ]);
  },

  data() {
    return {
      reload: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      formData: "",
      successMessage: "Successfully added Facial Device.",
    };
  },

  methods: {
    refresh() {
      this.reload = !this.reload;
    },

    closeSideBar(data) {
      this.formData = data;
      const sideBar = this.$refs["facial-device-list-sidebar-ref"];
      sideBar.hide();
      this.showAlert();
      this.reload = !this.reload;
    },

    openSideBar() {
      this.$store.commit("setRegisterDeviceFormSuccess", false);
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },

  computed: {
    ...mapGetters({ isSuccess: "getRegisterDeviceFormSuccess" }),
  },

  watch: {
    isSuccess(newSuccess) {
      console.log("Check is success", newSuccess);
      if (newSuccess) {
        this.refresh();
        this.$refs["facial-device-list-sidebar-ref"].hide();
        this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"), {
          centered: true,
        });
      }
    },
  },
};
</script>

<style scoped></style>
