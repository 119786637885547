<template>
  <div class="d-flex flex-column">
    <!-- begin: Facial Device Form    -->
    <b-form
      class="form"
      id="facial-device-form"
      v-on:submit.prevent="addFacialDevice"
    >
      <!-- begin: Name     -->
      <b-form-group :label="$t('DEVICE.FACIAL.PAGE.DEVICENAME')">
        <b-form-input
          id="facial-device-name-input"
          class="form-control h-auto"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          :placeholder="$t('DEVICE.FACIAL.PAGE.DEVICENAME1')"
        >
        </b-form-input>
      </b-form-group>
      <!-- end: Name     -->

      <!-- begin: Serial Number     -->
      <b-form-group :label="$t('DEVICEFORM.SERIALNUM')">
        <b-form-input
          id="facial-device-name-input"
          class="form-control h-auto"
          v-model="$v.form.code.$model"
          :state="validateState('code')"
          :placeholder="$t('DEVICEFORM.SERIALNUM1')"
          debounce="500"
        >
        </b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.code.$params.codeStatus">
          Code Status cannot be used. In use by other organisation
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- end: Serial Number     -->

      <!-- begin: Description     -->
      <b-form-group :label="$t('DEPARTMENTFORM.DESCRIPTION')">
        <b-form-textarea
          id="facial-device-name-input"
          class="form-control h-auto"
          v-model="$v.form.description.$model"
          :state="validateState('description')"
          :placeholder="$t('DETAILSFORM.DESC1')"
          max-rows="3"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end: Description     -->

      <!-- begin:date  -->
      <b-form-group :label="$t('DEVICEFORM.DATEPURCHASED')">
        <b-form-datepicker
          id="device-date-input"
          class="form-control h-auto"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:date  -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <b-button
          variant="primary"
          type="submit"
          ref="facial-register-form-submit"
          v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
    <!-- end: Facial Device Form    -->
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";

export default {
  name: "FacialDeviceForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        code: "",
        date: "",
        code_confirm: "",
      },
      isBusy: false,
      codeStatus: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      code: {
        required,
        minLength: minLength(6),
        codeExists: (value, vm) => {
          console.log(value, vm);
          console.log("Code Status", vm);
          return !vm.code_confirm;
        },
      },
      description: {
        required,
        minLength: minLength(3),
      },
      date: {
        required,
      },
      code_confirm: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    addFacialDevice() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.isBusy = true;
      this.$store
        .dispatch("registerDevice", {
          name: this.form.name,
          serial_number: this.form.code,
          description: this.form.description,
          from_date: this.form.date,
        })
        .then((response) => {
          this.$emit("success", response.data);
        })
        .catch((reason) => {
          this.$emit("failure", reason);
          this.$bvModal.msgBoxOk("Failed to add device", {
            centered: true,
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },

  computed: {
    ...mapState({
      items: (state) =>
        state.DeviceModule.FacialStore.FacialFormStore.responses,
    }),

    haha() {
      return false;
    },
  },

  watch: {
    form: {
      handler(form) {
        console.log("Watching", form);

        // Server-side validation
        const code = form.code;
        if (code) {
          const payload = {
            code: code,
          };

          this.$store
            .dispatch("checkExistingDeviceSerialNumber", payload)
            .then((response) => {
              console.log(response);
              this.form.code_confirm = response.data.status === "true";
              console.log("Confirm", this.form.code_confirm);
              if (response.data.status === "true") {
                console.log("Data Exist");
              }
            });
        }
      },
      deep: true,
    },

    isBusy(value) {
      console.log("Monitoring Busy", value);
    },
  },
};
</script>

<style scoped></style>
