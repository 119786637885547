<template>
  <div>
    <b-table
      id="facial-device-table"
      ref="facial-device-table"
      responsive="sm"
      :items="list"
      :fields="fields"
      show-empty
      :busy="isBusy"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- begin: Empty Table -->
      <template #empty="">
        <div class="d-flex align-content-center justify-content-center">
          <div class="svg-icon svg-icon-primary svg-icon-10x p-5">
            <inline-svg
              class="img-fluid"
              src="/media/svg/illustrations/list/undraw_team_page.svg"
            />
          </div>
          <p class="h5 pl-3 align-self-center text-secondary">
            No available employees
          </p>
        </div>
      </template>
      <!-- end: Empty Table -->

      <!-- A custom formatted header cell for field 'name' -->
      <!-- A virtual column -->
      <template v-slot:cell(device)="data">
        <div class="d-flex pl-3 align-items-center">
          <div class="symbol symbol-40 symbol-light mr-5">
            <span class="symbol-label svg-icon svg-icon-primary">
              <inline-svg
                class="h-100 align-self-end"
                src="/media/svg/icons/Devices/Tablet.svg"
              />
            </span>
          </div>
          <span class="text-dark-75 font-weight-bolder font-size-lg">{{
            data.item.name
          }}</span>
        </div>
      </template>

      <template v-slot:cell(model)="data">
        <div class="d-flex flex-column mt-1 mb-2">
          <span class="text-dark-75 font-weight-bolder font-size-lg">
            {{ data.item.model }}
          </span>

          <span>{{ data.item.make }}</span>
        </div>
      </template>

      <!-- Actions   -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showModal(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </button>
          <!-- end: Device Users Modal Button         -->

          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showDevice(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteDevices(data.item)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-devices button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader />
      </template>

      <template #empty="">
        <AppEmptyList
          text-top="All employees verified"
          text-bottom="You have no unverified users. To add new user, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <!-- begin:pagination  -->
    <div class="d-flex justify-content-end" v-if="list.length">
      <b-pagination
        pills
        size="lg"
        @change="change"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="facial-device-table"
      ></b-pagination>
    </div>

    <FacialDeviceTableUserModal ref="user-modal" v-bind:device="selected">
    </FacialDeviceTableUserModal>
    <FacialDeviceModal></FacialDeviceModal>
  </div>
  <!-- end:pagination  -->
</template>

<script>
import FacialDeviceTableUserModal from "@/modules/device/components/facial/FacialDeviceTableUserModal";
import FacialDeviceModal from "@/modules/device/components/facial/FacialDeviceModal";
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "FacialDeviceTable",
  components: {
    AppEmptyList,
    AppLoader,
    FacialDeviceTableUserModal,
    FacialDeviceModal,
  },
  props: ["reload"],
  beforeMount() {
    this.$store.dispatch("emptyFacialDevices");
    this.fetch();
  },
  data() {
    return {
      isBusy: false,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "device", label: this.$t("DEVICE.FACIAL.PAGE.DEVICE") },
        { key: "model", label: this.$t("DEVICE.FACIAL.PAGE.MODEL") },
        { key: "code", label: this.$t("DEVICE.FACIAL.PAGE.SERIALNUMBER") },
        { key: "actions", label: this.$t("DEVICE.FACIAL.PAGE.ACTIONS") },
      ],
      selected: {},
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("getFacialDevices")
        .finally(() => (this.isBusy = false));
    },

    deleteDevices(row) {
      console.log(row);

      this.$bvModal
        .msgBoxConfirm("Delete?", {
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.submitDelete(row.id);
          }
        });
    },

    submitDelete(id) {
      this.$store
        .dispatch("deleteFacialDevice", id)
        .then((response) => {
          console.log(response);
          this.$bvModal.msgBoxOk("Successfully Deleted Device", {
            centered: true,
          });
          this.fetch();
        })
        .catch(() => {
          this.$bvModal.msgBoxOk("Failed to delete Device", {
            centered: true,
            okVariant: "danger",
          });
        });
    },

    change(value) {
      console.log("Reload Table!");
      this.$store.commit("setFacialDevicesPage", value);
      this.fetch();
    },

    showModal(data) {
      console.log(data);
      this.selected = data.item;
      this.$bvModal.show("facial-device-page-user-modal");
    },

    showDevice(row) {
      console.log("Selected Device ", row);

      this.$store.dispatch("fetchFacialDeviceForm", row.item.id);
      this.$bvModal.show("facial-device-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      list: "getFacialDevices",
      pagination: "getFacialDevicesPagination",
    }),
  },
};
</script>

<style scoped></style>
