<template>
  <div>
    <b-table
      id="facial-device-employees-table"
      ref="facial-device-employees-table"
      borderless
      responsive="sm"
      :items="getItems"
      :fields="fields"
      :busy="isBusy"
      show-empty
      table-class="table-head-custom table-vertical-center table-head-bg"
      hover
      @row-clicked="handleCheckBox"
    >
      <!-- begin: When table's empty -->
      <template #empty="">
        <div class="d-flex align-content-center justify-content-center">
          <div class="svg-icon svg-icon-primary svg-icon-10x p-5">
            <inline-svg
              class="img-fluid"
              src="/media/svg/illustrations/list/undraw_team_page.svg"
            />
          </div>
          <p class="h5 pl-3 align-self-center text-secondary">
            {{ $t("DEVICEEMPLOYEE.STATUS") }}
          </p>
        </div>
      </template>
      <!-- end: When table's empty -->

      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->

      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(selected)="data">
        <div class="d-flex mt-1 mb-2 justify-content-center">
          <b-form-checkbox
            size="lg"
            v-model="data.item.selected"
            class="h6 mr-3"
          ></b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(name)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template #table-busy>
        <AppLoader />
      </template>
    </b-table>

    <!-- begin:pagination  -->
    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        @change="change"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="facial-device-table"
      ></b-pagination>
    </div>
    <!-- end:pagination  -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "FacialDeviceEmployeeAvailableTable",
  components: { CommonEmployeeSummary, AppLoader },
  props: {
    deviceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "selected", label: "Select", class: "text-center" },
        { key: "name", label: this.$t("EMPLOYEESGENERAL.MESSAGE.FIRSTNAME") },
      ],
      selected: {},
    };
  },
  mounted() {
    this.$store.dispatch("emptyFacialDeviceEmployeesNotIn");
  },
  methods: {
    getItems() {
      return this.$store
        .dispatch("getFacialEmployeeNotInList", this.deviceId)
        .then((value) => {
          console.log("Not In List", value.data.data);
          return value.data.data;
        })
        .catch(() => []);
    },

    handleCheckBox(row) {
      console.log(row);
      const index = this.items.findIndex((e) => e.id === row.id);
      this.items[index].selected = !this.items[index].selected;
    },

    change(page) {
      console.log("Reload Table!", page);
      this.$store.commit("setFacialEmployeeListCurrentPage", page);
      this.$refs["facial-device-employees-table"].refresh();
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.DeviceModule.FacialStore.FacialEmployeeList.items,
      afterSelected: (state) =>
        state.DeviceModule.FacialStore.FacialEmployeeList.afterSelected,
    }),
    ...mapGetters({ pagination: "getFacialEmployeeListPagination" }),
  },
  watch: {
    afterSelected(newValue) {
      console.log("Selected employees changes!");
      if (newValue !== null) {
        this.$refs["facial-device-employees-table"].refresh();
      }
    },
  },
};
</script>

<style scoped></style>
