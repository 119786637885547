<template>
  <div>
    <b-form v-if="!isBusyForm" v-on:submit.prevent="onSubmit">
      <!-- begin: Facial Device Form    -->
      <b-form-group
        label-cols-lg="2"
        :label="$t('DEPARTMENTFORM.NAME')"
        label-for="facial-device-form"
      >
        <b-form-input
          id="facial-device-form"
          :placeholder="$t('DEPARTMENTFORM.NAME1')"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
        >
        </b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.name.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-lg="2"
        :label="$t('DEVICEFORM.SERIALNUM')"
        label-for="facial-device-name-input"
      >
        <b-form-input
          id="facial-device-name-input"
          :placeholder="$t('DEVICEFORM.SERIALNUM1')"
          v-model="$v.form.code.$model"
          :state="validateState('code')"
        >
        </b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.code.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.code.$params.codeStatus">
          Code Status cannot be used. In use by other organisation
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-lg="2"
        label-for="description"
        :label="$t('DEPARTMENTFORM.DESCRIPTION')"
      >
        <b-form-textarea
          id="description"
          v-model="$v.form.description.$model"
          :placeholder="$t('DEPARTMENTFORM.DESCRIPTION1')"
          :state="validateState('description')"
        ></b-form-textarea>

        <b-form-invalid-feedback v-if="!$v.form.description.required"
          >{{ $t("ALERT.ALERT") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-for="device-date-input"
        label-cols-lg="2"
        :label="$t('DEVICEFORM.DATEPURCHASED')"
      >
        <b-form-datepicker
          id="device-date-input"
          class="form-control h-auto"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
        >
        </b-form-datepicker>
      </b-form-group>

      <!-- end:date  -->
      <div class="d-flex justify-content-end border-top pt-3">
        <b-button
          ref="employee-general-button"
          variant="primary"
          type="submit"
          v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
    <b-skeleton-wrapper :loading="isBusyForm">
      <template #loading>
        <b-skeleton class="mt-2" type="input"></b-skeleton>
        <b-skeleton class="mt-5" type="input"></b-skeleton>
        <b-skeleton class="mt-10" type="input"></b-skeleton>

        <b-skeleton class="mt-5 align-self-end" type="button"></b-skeleton>
      </template>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";

export default {
  name: "FacialDeviceEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        code: "",
        date: "",
        code_confirm: "",
      },
      isBusy: false,
      id: null,
      codeStatus: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      code: {
        required,
        minLength: minLength(6),
        codeExists: (value, vm) => {
          console.log(value, vm);
          console.log("Code Status", vm);
          return !vm.code_confirm;
        },
      },
      description: {
        required,
        minLength: minLength(3),
      },
      date: {
        required,
      },
      code_confirm: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.formBusy = !this.formBusy;

      const submitForm = {
        id: this.id,
        name: this.form.name,
        description: this.form.description,
        serial_number: this.form.code,
        from_date: this.form.date,
      };

      this.isBusy = true;
      this.$store
        .dispatch("updateFacialDeviceForm", submitForm)
        .then(() => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"), {
            centered: true,
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },

  computed: {
    ...mapGetters({
      fetchedForm: "getFacialDeviceForm",
      isBusyForm: "getFacialDeviceFormBusy",
    }),
  },

  watch: {
    fetchedForm(newForm) {
      if (newForm !== null) {
        console.log("Set New Form", newForm);
        this.form.name = newForm.name;
        this.form.description = newForm.description;
        this.form.code = newForm.code;
        this.form.date = newForm.date;
        this.id = newForm.id;
      }
    },
    form: {
      handler(form) {
        console.log("Watching", form);

        // Server-side validation
        const code = form.code;
        if (code) {
          const payload = {
            code: code,
            id: this.id,
          };
          this.$store
            .dispatch("checkExistingDeviceSerialNumber", payload)
            .then((response) => {
              console.log(response);
              this.form.code_confirm = response.data.status === "true";
              console.log("Confirm", this.form.code_confirm);
              if (response.data.status === "true") {
                console.log("Data Exist");
              }
            });
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
