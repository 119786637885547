<template>
  <b-modal
    id="facial-device-modal"
    :title="$t('DEVICE.FACIAL.PAGE.HEADER')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column px-3 py-2">
      <FacialDeviceEditForm></FacialDeviceEditForm>
    </div>
  </b-modal>
</template>

<script>
import FacialDeviceEditForm from "@/modules/device/components/facial/FacialDeviceEditForm";
export default {
  name: "FacialDeviceModal",
  components: { FacialDeviceEditForm },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
};
</script>

<style scoped></style>
